import styled from 'styled-components'
import { up } from 'src/utils/media'

const StyledFooter = styled.div`
  display: flex;
  flex-flow: row wrap;
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
  order: 2;

  ${up('tablet')} {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding-bottom: 3rem;
  }

  & > div {
    display: flex;
    width: 100%;
    margin-top: 4rem;
    margin-bottom: 8rem;

    a {
      display: flex;
      height: 4rem;
      width: auto;
      margin: 0 auto;
    }

  }

  & > a {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    margin-left: -1em;

    & > span {
      font-size: 0.8em;
      line-height: 1;
      margin-right: 0.5em;
      transition: color 0.7s ease-out;
    }

    &:hover > span, &:active > span {
      color: ${props => props.theme.colors.primary};
    }

    &:hover svg, &:active svg {
      opacity: 0.75;
    }
  }


  ${up('tablet')} {
    padding-bottom: 2rem;

    svg {
      width: 6rem;
      height: 1.2rem;
    }
  }

  ${up('desktop')} {
    padding-bottom: 5rem;

    & > a {

      & > span {
        font-size: 1em;
      }

      & > svg {
        width: 7.6rem;
        height: 1.6rem;
      }
    }
  }

`

export default StyledFooter
