import React from 'react'
import StyledLink from './style'

interface NavLinkProps {
  to: string
  exact?: boolean
}

const NavLink: React.FC<NavLinkProps> = props => {
  return (
    <StyledLink
      to={props.to}
      activeClassName="active"
      partiallyActive={!props.exact}
    >
      {props.children}
    </StyledLink>
  )
}

NavLink.defaultProps = {
  exact: false
}

export default NavLink
