import React, { useEffect } from 'react'
import anime from 'animejs'
import useMedia from '@baevra/media/useMedia'
import Wrapper from './style'

const Layout: React.FC = props => {
  const isMobile = useMedia('mobile')
  const isTablet = useMedia('tablet')

  if ((typeof document !== 'undefined')) {
    const vh = document.documentElement.clientHeight * 0.01
    const vw = document.documentElement.clientWidth * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    document.documentElement.style.setProperty('--vw', `${vw}px`)
  }

  const listener = () => {
    const vh = window.innerHeight * 0.01
    const vw = window.innerWidth * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    document.documentElement.style.setProperty('--vw', `${vw}px`)
  }

  const parallax = (event:MouseEvent) => {
    const wrapper = document.querySelector('.parallax-group')
    const layers:NodeListOf<HTMLElement> = document.querySelectorAll('.parallax-item') ?? null
    const x = event.screenX - (wrapper?.clientWidth ?? 0) / 2
    const y = event.screenY - (wrapper?.clientHeight ?? 0) / 2

    layers?.forEach((layer:HTMLElement) => {
      const deep:number = parseInt(layer.getAttribute('data-deep') ?? '1')
      const disallow = layer.getAttribute('data-disallow')

      const itemX:number = (disallow && disallow === 'x') ? 0 : x / deep
      const itemY:number = (disallow && disallow === 'y') ? 0 : y / deep

      if(disallow && disallow === 'both') return

      anime({
        targets: '.parallax-item',
        duration: 300,
        easing: 'linear',
        update: function() {
          layer.style.transform = `translate(${itemX}%, ${itemY}%)`
        }
      })
    })
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      listener()
    }
  }, [])

  useEffect(() => {
    if (!isMobile && !isTablet) {
      window.addEventListener('mousemove', parallax)
      return () => {
        window.removeEventListener('mousemove', parallax)
      }
    }
  }, [isMobile, isTablet])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', listener)
      window.addEventListener('orientationchange', listener)
      return () => {
        window.removeEventListener('resize', listener)
        window.removeEventListener('orientationchange', listener)
      }
    }
  })

  return (
    <Wrapper>
      {props.children}
    </Wrapper>
  )
}

export default Layout
