import styled from 'styled-components'
const Steps = require('src/public/images/steps_mobile.svg')

const Wrapper = styled.div`

  input {
    display: none;
  }

  input + label {
    display: flex;
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;
    top: 50%;
    right: 2rem;
    transform: translate(0,-50%);
    z-index: 8000;
    transform-style: preserve-3d;
    transition: right .7s ease-out,
                transform .7s ease-out 1s;

    & > span {
      width: 2.5rem;
      height: 2px;
      border-radius: 1px;
      background-color: ${props => props.theme.colors.primary};
      position: absolute;
      transform-style: preserve-3d;
      transition: all .3s ease-out .7s;

      &:nth-child(1) {
        top: .5rem;
      }

      &:nth-child(2) {
        top: calc(50% - 1px);
      }

      &:nth-child(3) {
        top: calc(100% - .5rem);
        width: 50%;
        left: 0;
        transform: none;
      }

    }

    & + .mobile-menu {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: calc(100% * var(--vh));
      background-color: ${props => props.theme.colors.primary};
      z-index: 7000;
      transform-style: preserve-3d;
      transform: translateX(100%);
      transition: all .7s ease-out .7s;

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-image: url(${Steps});
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        opacity: 0;
        transition: opacity .3s ease-out;
      }

      & > ul {
        display: flex;
        flex-flow: column;
        flex-shrink: 0;
        list-style: none;
        position: absolute;
        width: auto;
        height: 50%;
        left: 50%;
        white-space: nowrap;
        justify-content: center;
        opacity: 0;
        transition: opacity .3s ease-out;

        li {
          display: flex;
          padding: 0;
          margin: 0 auto;
          text-align: center;
          align-items: center;
          color: ${props => props.theme.colors.background};

          & > a {
            color: inherit;
            display: flex;
            align-items: center;
          }
        }

        &.navigation {
          margin: 1em auto;
          padding: 10rem 0 6rem 0;
          top: 50%;
          transform: translate(-50%,-100%);

          @media screen and (max-height: 500px) {
            padding: 5rem 0 3rem 0;
          }
          
          & > li {
            font-size: 2rem;         
            margin: 1em auto;
          }
        }

        &.market {
          padding: 6rem 0 12rem 0;
          top: 50%;
          transform: translate(-50%, 0);

          @media screen and (max-height: 500px) {
            padding: 3rem 0 6rem 0;
          }

          & > li {
            font-size: 1.4rem;
            margin: 1em 0;
            justify-content: center;
          }
        }
      }

      & > div {
        position: absolute;
        left: 50%;
        bottom: 5rem;
        transform: translateX(-50%);
        width: 100%;
        text-align: center;
        opacity: 0;
        transition: opacity .3s ease-out;

        @media screen and (max-height: 500px) {
          bottom: 3rem;
        }
      }
    }

  }

  input:checked + label {    
    right: 50%;
    transform: translate(50%,-50%);
    transition: right .7s ease-out 1s,
                transform .7s ease-out;

    span {
      background-color: ${props => props.theme.colors.background};
      transition: all .3s ease-out;

      &:nth-child(1) {
        top: calc(50% - 1px);
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        width: 100%;
        top: calc(50% - 1px);
        transform: rotate(-45deg);
      }
    }

    & + .mobile-menu {
      transform: translateX(0);
      transition: all .7s ease-out .3s;

      ul.navigation {        
        opacity: 1;        
        transition: opacity .3s ease-out 2s;
      }

      ul.market {        
        opacity: 1;        
        transition: opacity .3s ease-out 2.3s;
      }

      & > div {       
        opacity: 1;        
        transition: opacity .3s ease-out 2.6s;
      }

      &::after {
        opacity: 1;        
        transition: opacity .3s ease-out 1.5s;
      }
    }
  }
`

export default Wrapper
