import React from 'react'
import { Link } from 'gatsby'
import Copyright from 'src/components/Typography/Copyright'
import NavLink from 'src/components/NavLink'
import MobileMenu from 'src/components/Buttons/MobileMenu'
import Logo from 'src/components/Logo'
//@ts-ignore
import DownloadAndroid from 'src/public/icons/download_android.inline.svg'
//@ts-ignore
import DownloadIos from 'src/public/icons/download_ios.inline.svg'
import StyledHeader from './style'

interface Props {
  small?: boolean
}

const Header:React.FC<Props> = props => {
  const { small } = props
  const backLink:any = React.useRef()

  const handleCloseClick = () => {
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line
      (window.history.length > 2) ? window.history.back() : backLink?.current?.click()
    }

  }

  const smallContent = (
    <div className="wrapper">
      <div className="right">
        <div onClick={handleCloseClick}>Закрыть</div>
      </div>
    </div>
  )

  return (
    <StyledHeader small={small}>
      <div className="desktop">
        {small ? smallContent
          : (
            <div className="wrapper">
              <div><Copyright>Бийск — 2020</Copyright></div>
              <div className="market">
                <a className="get_ios" href="/"><DownloadIos />Загрузить для iOS</a>
                <a className="get_android" href="/"><DownloadAndroid />Загрузить для Android</a>
              </div>
              <div><NavLink exact to="/rules">Правила</NavLink></div>
            </div>
          )
        }
      </div>
      <div className="mobile">
        <Link to="/" ref={backLink}><Logo /></Link>
        <span className="title">Почетный Горожанин</span>
        <MobileMenu />
      </div>
    </StyledHeader>
  )
}

export default Header
