import styled from 'styled-components'
import { Link } from 'gatsby'

const StyledLink = styled(Link)`
  font-family: ${props => props.theme.fontFamily};
  color: ${props => props.theme.colors.primary};
  font-size: inherit;
  letter-spacing: 0em;
  line-height: inherit;
  font-weight: inherit;
  transition: all .7s ease-out;

  &:hover, &:active {
    color: ${props => props.theme.colors.primaryLight};
  }
`

export default StyledLink
