import React from 'react'
import { Helmet } from 'react-helmet'

interface Props {
  title?: string
  description?: string
  url: string
  ogImage?: {
    src: string,
    width: string,
    height: string
  }
}

const SEO: React.FC<Props> = props => {
  return (
    <Helmet defer={false}>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />

      <title>{props.title}</title>
      <meta name="description" content={props.description} />

      <meta property="og:locale" content="ru-RU" />
      <meta
        property="og:site_name"
        content="Почетный горожанин"
      />

      <meta property="og:url" content={props.url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={props.title} />
      <meta property="og:description" content={props.description} />

      <meta name="twitter:site" content={props.url} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={props.ogImage?.src} />

      <meta property="og:image" content={props.ogImage?.src} />
      <meta property="og:image:width" content={props.ogImage?.width} />
      <meta property="og:image:height" content={props.ogImage?.height} />

      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="72x72" href="/favicon-72x72.png" />
      <link rel="icon" type="image/png" sizes="48x48" href="/favicon-48x48.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#3c73ef" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/mstile-144x144.png" />
      <meta name="theme-color" content="#ffffff" />
    </Helmet>
  )
}

SEO.defaultProps = {
  // eslint-disable-next-line
  description: 'Посещайте значимые места Бийска, соревнуйтесь с другими участниками и получайте реальные награды!',
  title: 'Почетный горожанин',
  ogImage: {
    src: require('src/public/sharequest.jpg'),
    width: '1200',
    height: '630'
  }
}

export default SEO
