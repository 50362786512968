import React from 'react'
import StyledImage from './style'
const LogoImage = require('src/public/images/logo.png')

const Logo:React.FC = () => {
  return (
    <StyledImage src={LogoImage} alt="Почетный горожанин" className="logo" />
  )
}

export default Logo
