import React, { useState } from 'react'
import Copyright from 'src/components/Typography/Copyright'
import NavLink from 'src/components/NavLink'
//@ts-ignore
import AppStore from 'src/public/icons/app-store-black.inline.svg'
//@ts-ignore
import PlayMarket from 'src/public/icons/play-market.inline.svg'
import Wrapper from './style'

const MobileMenu = () => {
  const [isOpen, setMenuOpen] = useState<boolean>(false)

  const handleMenuOpen = () => {
    const body:HTMLBodyElement = document.getElementsByTagName('body')[0] ?? <body />
    if (isOpen) {
      body?.classList?.remove('fixed')
    } else  {
      body?.classList?.add('fixed')
    }
    setMenuOpen(!isOpen)
  }

  return (
    <Wrapper>
      <input type="checkbox" name="hamburger" id="mobile-menu-toggle"/>
      <label htmlFor="mobile-menu-toggle" onClick={handleMenuOpen}>
        <span></span>
        <span></span>
        <span></span>
      </label>
      <div className="mobile-menu">
        <ul className="navigation">
          <li><NavLink exact to="/">В начало</NavLink></li>
          <li><NavLink exact to="/rules">Правила</NavLink></li>
        </ul>
        <ul className="market">
          <li><a className="get_ios" href="/"><AppStore /></a></li>
          <li><a className="get_android" href="/"><PlayMarket /></a></li>
        </ul>
        <div><Copyright>Бийск — 2020</Copyright></div>
      </div>
    </Wrapper>
  )
}

export default MobileMenu
