import styled from 'styled-components'
import { up } from 'src/utils/media'

const StyledImage = styled.img`
  display: flex;
  margin: 0 auto;
  object-fit: contain;
  width: 8rem;
  height: 8rem;
  flex: 0 0 auto;

  ${up('desktop')} {
    width: 12rem;
    height: 12rem;
  }

  ${up('largeDesktop')} {
    width: 25rem;
    height: 25rem;
  }
`

export default StyledImage
