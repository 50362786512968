import styled from 'styled-components'
import { up } from 'src/utils/media'

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1 0 100%;

  section {
    display: flex;
    flex-flow: column;
  }

  main {
    display: flex;
    flex-flow: column;
    flex: 0 0 auto;
    height: 100%;
    position: relative;
    overflow-x: hidden;
    margin: 0 auto;
    width: 100%;

    ${up('tablet')} {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    
  }

`

export default Wrapper
