import React from 'react'
import Copyright from 'src/components/Typography/Copyright'
//@ts-ignore
import Baevra from 'src/public/icons/baevra.inline.svg'
//@ts-ignore
import AppStore from 'src/public/icons/app-store-black.inline.svg'
//@ts-ignore
import PlayMarket from 'src/public/icons/play-market.inline.svg'
import StyledFooter from './style'

const Footer:React.FC = () => {
  return (
    <StyledFooter>
      <div className="mobile">
        <a className="get_ios" href="/"><AppStore /></a>
        <a className="get_android" href="/"><PlayMarket /></a>
      </div>
      <a href="https://baevra.com/ru">
        <Copyright>Разработано в</Copyright>
        <Baevra />
      </a>
    </StyledFooter>
  )
}

export default Footer
