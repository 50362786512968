import styled from 'styled-components'
import { up } from 'src/utils/media'

const StyledCopyright = styled.span`
  font-family: ${props => props.theme.fontFamily};
  color: ${props => props.theme.colors.secondary[200]};
  font-size: 1.6rem;
  letter-spacing: 0em;
  line-height: 2.2;
  font-weight: 500;

  ${up('largeDesktop')} {
    font-size: 3.2rem;
  }
`

export default StyledCopyright
