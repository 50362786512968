import styled from 'styled-components'
import { up } from 'src/utils/media'
import theme from 'src/theme'

interface Props {
  small?: boolean
}

const whiteColor = theme.colors.background

const StyledHeader = styled.header<Props>`
  display: flex;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9000;
  background-color: ${props => props.theme.colors.background};
  border-bottom: 1px solid ${props => props.theme.colors.path};

  ${up('tablet')} {
    background-color: transparent;
    ${props => !props.small
      ? `background-image: linear-gradient(to bottom, ${whiteColor} 70%, rgba(255,255,255,0));`
      : `background: none;`
    }

    border: none;
  }

  & > .desktop {
    width: 100%;
  }

  & > .desktop > .wrapper {
    display: flex;
    width: ${props => props.theme.containerWidth.sm};
    height: 3.6rem;
    margin: 2rem auto;
    align-items: center;

    ${up('tablet')} {
      width: ${props => props.theme.containerWidth.lg};
      margin: 4.5rem auto;
    }

    & > .right {
      margin-left: auto;
      float: right;

      & > div {
        color: ${props => props.theme.colors.primary};
        cursor: pointer;

        &:hover {
          color: ${props => props.theme.colors.primaryLight};
        }
      }
    }

    & > .market {
      display: flex;
      margin: 0 auto;
      justify-content: space-between;

      & > a {
        display: flex;
        position: relative;
        align-items: center;
        margin: 0 4rem;
        font-family: ${props => props.theme.fontFamily};
        color: ${props => props.theme.colors.downloadLink};
        font-size: 1.4rem;
        letter-spacing: 0.015em;
        line-height: 1.5;
        font-weight: 500;
        white-space: nowrap;
        transition: opacity 0.7s ease-out;

        ${up('largeDesktop')} {
          font-size: 2.5rem;
          margin: 0 8rem;
        }

        &.get_ios {
          width: 17.5rem;

          ${up('largeDesktop')} {
            width: 28rem;
          }
        }

        &.get_android {
          width: 20rem;

          ${up('largeDesktop')} {
            width: 33rem;
          }
        }
        
        &::after {
          content: '';
          display: block;
          position: absolute;
          width: 3.6rem;
          height: 3.6rem;
          top: 0;
          left: 0;
          border: 1px solid ${props => props.theme.colors.border};
          border-radius: 1.8rem;
          transition: all 1s ease-out;
        }

        & > svg {
          margin-right: 1em;
          transition: all 0.7s ease-out;

          & > g > g:nth-child(1) {
            stroke: ${props => props.theme.colors.background};
          }
        }

        &:hover, &:active {
          color: ${props => props.theme.colors.secondary[500]};
          opacity: 0.7;

          &::after {
            width: 100%;
            border-color: ${props => props.theme.colors.secondary[500]};
          }

          & > svg {
            margin-right: 0;
          }
        }
       
      }
    }
  }

  & > .mobile {
    display: flex;
    width: 100%;
    height: 8.2rem;
    margin: 0;
    position: relative;
    padding-right: 4.5rem;

    .logo {
      height: 3.6rem;
      width: 3.3rem;
      float: left;
      margin: 2.3rem 0 2.3rem 2rem;
    }

    .title {
      display: flex;
      font-weight: 800;
      letter-spacing: 0.015em;
      align-self: center;
      margin: 0 auto;
    }

    @media screen and (max-height: 600px) {
      height: 6.2rem;

      .logo {
        margin: 1.3rem 0 1.3rem 2rem;
      }
    }
  }
`

export default StyledHeader
